import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Willkommen bei QuickFix
			</title>
			<meta name={"description"} content={"Schnelle Lösungen, zuverlässige Ergebnisse"} />
			<meta property={"og:title"} content={"Willkommen bei QuickFix"} />
			<meta property={"og:description"} content={"Schnelle Lösungen, zuverlässige Ergebnisse"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Components.Header1 />
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Willkommen bei QuickFix
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Wir bei QuickFix PC Repair wissen, wie wichtig Ihre Technologie für Ihren täglichen Betrieb ist. Egal, ob Sie gegen eine Malware-Invasion kämpfen oder unter einem Hardwarefehler leiden, unser Team ist hier, um Ihnen schnelle und zuverlässige Lösungen zu bieten, damit Sie schnell wieder auf Kurs kommen. Vielen Dank, dass Sie sich für uns entschieden haben – wir sind der Ort, an dem Ihre PC-Probleme auf ihre Lösungen treffen.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" border-color="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09%3A42%3A42.304Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline4"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Über uns
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					QuickFix PC Repair hat sich der Bereitstellung erstklassiger PC-Reparaturdienste verschrieben. Unser Team aus zertifizierten Technikern verfügt über das Wissen und die Werkzeuge, die zur Diagnose und Behebung aller Computerprobleme erforderlich sind. Von der Software-Fehlerbehebung bis zur Hardware-Reparatur sorgen wir dafür, dass Ihr Computer so reibungslos läuft wie am ersten Tag.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 120px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Unser Engagement
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Wir beheben nicht nur Ihre Probleme, sondern stellen auch sicher, dass Sie verstehen, was schiefgelaufen ist und wie Sie zukünftigen Problemen vorbeugen können. Unser transparenter Prozess und unsere ehrliche Beratung sind darauf ausgerichtet, die Lebensdauer Ihres PCs zu verlängern, was QuickFix zu einem Partner macht, dem Sie vertrauen können.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09:42:42.265Z"
					width="100%"
					max-height="293px"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-4.jpg?v=2024-07-12T09%3A42%3A42.265Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0">
			<Override slot="SectionContent" align-items="flex-end" />
			<Text
				margin="0px 0px 16px 0px"
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
			>
				Warum QuickFix wählen?
			</Text>
			<Text
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				/>
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
			>
				<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
					Erfahrene Techniker: Dank der technischen Expertise unseres Teams können wir jedes PC-Problem lösen.
					<br />
					<br />
					Schneller Service: Wir wissen, dass Sie nicht warten können, deshalb legen wir Wert auf schnelle Bearbeitungszeiten.
				</Text>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="0px 40px">
					<Box>
						<Image
							src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09:42:42.277Z"
							max-height="120px"
							width="100%"
							object-fit="cover"
							lg-height="120px"
							srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Transparente Preise
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09:42:42.263Z"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Dauerhafte Reparaturen
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});